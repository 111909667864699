@use '@angular/material' as mat;

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      density: 0,
    ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($my-theme);

// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($my-theme);
@import "../node_modules/angular-notifier/styles";

// overrided css
@import './assets/style/variable.scss';
@import './assets/style/font.scss';
@import "./assets/style/typography.scss";
@import "./assets/style/override.scss";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
// Include the theme mixins for other components you use here.

// variables 

/* You can add global styles to this file, and also import other style files */

// Css for desktop

:root {
  --clr-header-6-bg-color: #753BBD;
  --custom-light-primary: rgba(117, 59, 189, .05);
  --clr-thead-bgcolor: #F8F5FC;
  --clr-thead-bgcolor-grey: rgba(73, 73, 73, .10);
  --clr-nav-selected-bg-color: #F8F5FC;
  --clr-color-neutral-200: #F8F5FC;
  --clr-forms-focused-color: #753BBD;
  --clr-dropdown-item-color: #753BBD;
  --clr-btn-primary-outline-color: #753BBD;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3 {
  font-weight: 600 !important;
}

.text-light,
h4 {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
span,
p,
td,
tr {
  color: #494949;
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
  color: #753BBD;
}

table thead tr {
  // background-color: var(--clr-thead-bgcolor-grey) !important;
  background-color: #53565A !important;
}

.extended-table th {
  background-color: #53565A !important;
  width: fit-content;
  color: white;
}

.extended-table th tr {
  display: flex;
  flex-direction: row
}

// include header style

@import "../src/assets/style/header.scss";

.login-card-header .mat-card-header-text {
  margin: 0;
}

.basic-table-layout table {
  text-align: left;
  margin-top: 45px;
}

.basic-table-layout thead tr th {
  font-weight: 500;
  font-size: 14px;
  text-align: left;
}

.basic-table-layout tbody tr td {
  text-align: left;
  vertical-align: middle;
}

.progress-static>.progress-meter::before {
  background-color: orange;
}

.top-bar {
  background-color: #cedde0;
}

.top-bar h2 {
  color: #002538;
}

.avt-img {
  border-radius: 50%;
  width: 40px;
}

// style="background-color: #53565A; color: white"

body {
  background-color: #fafafa;
}

.notifier__container ul:empty {
  display: none;
}

.modal-header .close {
  position: absolute;
  right: 0;
  top: 15px;
  padding: 0 1rem;
}

.btn-primary .btn,
.btn.btn-primary {
  border-color: #753BBD !important;
  background-color: #753BBD !important;
  color: #fff !important;
  text-decoration: none;
}

.btn-primary .btn:hover,
.btn.btn-primary:hover {
  outline: none;
  background: transparent !important;
  color: #753BBD !important;
  border-color: #753BBD !important;
  text-decoration: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.header.header-6 .navbar-collapse {
  display: none;
}

header.header-6 .navbar {
  box-shadow: none;
  width: 100%;
}

header.header-6 #navbarNav .navbar-nav {
  display: none;
}

header.header-6 .header-nav,
.header-actions {
  align-items: center;
  vertical-align: middle;
  display: flex !important;
  height: auto !important;
}

header.header-6 .header-nav .dropdown button {
  margin-top: 0 !important;
}

.navbar-collapse {
  position: absolute;
  left: -100%;
  top: 60px;
  width: 100%;
  // background: #002538;
  background: #753BBD;
  z-index: 99;
  border-top: 1px solid;
  padding: 15px 25px;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  height: calc(100vh - 60px);
  display: block !important;
}

.navbar-collapse.show {
  left: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  z-index: 99999;
}

.data-sheet {
  margin-top: 10px;
  color: #002538 !important;
}

table td a {
  cursor: pointer;
}

body {
  background: #f8f8f8 0% 0% no-repeat padding-box;
}

.basic-table-layout {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  // position: relative;
  z-index: 998;
  // margin-top: 30px;
  // box-shadow: 0px 4px 0px 0px rgb(0 0 0 / 10%);
  border: none;
}

.basic-table-layout.single-page {
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 16%);
  border: none;
}

.basic-table-layout table {
  border: none;
  margin-top: 0;
  border-bottom: 1px solid;
}

.basic-table-layout table thead th {
  // background: #002538;
  background: #53565A;
  color: #fff;
  border-bottom: none;
}

.basic-table-layout table tbody tr:nth-child(odd) {
  background-color: white;
}

.basic-table-layout table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.basic-table-layout table tbody tr td a {
  color: #002538 !important;
}

.basic-table-layout table tbody tr {
  border: none !important;
  border-style: none;
}

// header.header-6 .branding,
// header.header-6 .branding .nav-link {
//   padding-left: 0;
// }

header.header-6 .header-actions .dropdown button.dropdown-toggle:not(.btn) {
  padding-right: 12px !important;
}

section {
  padding: 24px;
  position: relative;
}

.nested-section section {
  padding: 0;
}

.nested-section section .table-header-custom {
  padding: 0;
}

// .bg-section {
//   height: 170px;
//   width: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   background-repeat: no-repeat;
//   // background: url(assets/images/bg.svg);
//   background-size: cover;
// }

.bg-section-wrapper{
  z-index: 99;
}

.flex-revers {
  display: flex;
  text-align: right;
  align-items: center;
  flex-flow: row-reverse;
}

.overflow-desktop {
  overflow-x: auto;
}

.vrt-middle {
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
}

input[type="text"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 5px 10px;
}

.search-box {
  position: relative;
  margin-bottom: 0;
}

.search-box input[type="text"] {
  padding-left: 25px;
  outline: none;
}

.data-table .dropdown-toggle::after {
  content: none !important;
}

.data-table .dropdown .dropdown-toggle:not(.btn) {
  padding: 0px;
}

.search-box:before {
  content: "\f002";
  font-family: "FontAwesome";
  font-weight: 900;
  display: block;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: $primary-color;
}

.dropdown button.dropdown-toggle:not(.btn).filter-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: 15px;
  min-width: 120px;
  text-align: left;
  position: relative;
}

.dropdown button.dropdown-toggle:not(.btn).filter-dropdown:after {
  content: "\f0b0";
  font-family: "FontAwesome";
  font-weight: 900;
  display: block;
  position: absolute;
  right: 9px;
  border: none;
  top: 50%;
  transform: translateY(-50%);
}

.filter-drop-down .dropdown .dropdown-menu .dropdown-item {
  outline: none;
}

.table-header-custom {
  padding: 24px;
  overflow: hidden;
}

.drop-trigger {
  color: #fff !important;
}

.clr-input-group .clr-input {
  background: 0 0 !important;
}

clr-icon {
  vertical-align: unset !important;
}

.basic-table-layout table select,
.basic-table-layout table input {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  height: 35px;
  min-height: 35px;
  min-width: 100px;
  width: 100%;
}

.basic-table-layout table select {
  padding-right: 20px;
}

.basic-table-layout table select:hover {
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.basic-table-layout table textarea {
  max-height: 35px;
  min-width: 200px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  width: 100%;
}

.basic-table-layout table .clr-form-control,
.basic-table-layout table .clr-textarea-wrapper {
  margin-top: 0;
}

.basic-table-layout table .clr-select-wrapper::after {
  top: 13px;
  z-index: 99;
}

// .basic-table-layout table select#project, .basic-table-layout table select#task{
//   min-width: 150px;
//   width: 100%;
// }
// .basic-table-layout table input#hours{
//   width: 100px;
// }

.add-icon-row {
  padding: 0 !important;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  min-width: 30px !important;
  line-height: 0;
  border: none;
  box-shadow: none;
  color: #002538 !important;
}

.table-caption p {
  margin: 0;
}

.profiller-image {
  height: 25px;
  width: 25px;
  border: 1px solid #C7C7C7;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 10px;
  padding: 4px;
}

.profiler-content h5 {
  margin: 0;
  line-height: 1em;
  font-size: 14px !important;
  color: #53565A;
}

.profiler-content p {
  margin: 0;
  font-size: 12px !important;
  color: #53565A !important;
}

.user-profiler {
  padding: 0 15px;
}

.basic-table-layout .clr-select-wrapper,
.basic-table-layout .clr-input-wrapper,
.stepper-component .clr-select-wrapper,
.stepper-component .clr-input-wrapper {
  height: auto;
  max-height: fit-content;
  width: 100%;
}

.stepper-component .clr-control-container {
  width: 100%;
}

.basic-table-layout .table-caption {
  text-align: right;
  width: 100%;
  padding-right: 15px;
}

.basic-table-layout .table-caption h5 {
  font-weight: 600;
  font-style: italic;
}

.table-header-custom .user-profiler {
  width: fit-content;
  margin-right: 0;
  // margin-left: auto;
}

.table-bordered td,
.table-bordered th {
  border-left: 1px solid #dee2e6 !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

// body {
//   margin: 0;
//   padding: 2rem;
// }

.table-sticky-header {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

.table-sticky-header th {
  background: white;
  position: sticky;
  top: 0;
  /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

// Style for employee page

.employee-img {
  box-shadow: 4px 4px 0px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  width: 107px;
  height: 120px;
  position: relative;
  z-index: 990;
}

.tab-content>.active {
  opacity: 1;
}

.global-tabs {
  padding: 0 0;
  // border: 1px solid #d5d5d5;
  border-radius: 4px;
  background-color: #fff;
  padding-bottom: 0;
}

.global-tabs .nav-tabs {
  box-shadow: none;
  border-bottom: none;
  padding-left: 24px;
  height: auto;
  padding-top: 6px;
}

.global-tabs .nav-tabs li button {
  border: none;
  font-size: 18px;
  font-weight: 600;
  background: none;
}

.global-tabs .nav .nav-link.active,
.global-tabs .nav .nav-link:hover {
  // box-shadow: 0 -2px 0 #753BBD inset;
  font-size: 14px;
  font-weight: 400;
  color: #753BBD !important;
  background-color: transparent;
  background: transparent;
  line-height: 1em;
  padding-bottom: 10px;
  border-color: transparent;
  // border-bottom-width: 3px;
}

.global-tabs .nav .nav-link.active::before,
.global-tabs .nav .nav-link:hover::before {
  border-bottom-color: #753BBD;
  border-bottom-width: 4px
}

.global-tabs .nav .nav-link {
  font-size: 14px;
  font-weight: 400;
  color: #888888 !important;
  background-color: transparent;
  background: transparent;
  line-height: 1em;
  padding-bottom: 10px;
}

.heading-small-c {
  color: grey !important;
  font-size: 14px;
  margin: 0;
}

.heading-small-high {
  font-size: 16px;
  margin: 0;
  font-family: Metropolis, "Avenir Next", "Helvetica Neue", Arial, sans-serif;
}

.column-div {
  padding: 25px 0;
}

.global-tabs-outer .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 4px 0px 0px rgb(0 0 0 / 10%);
  border: none;
  // padding: 15px;
}

.global-tabs-outer .card h4 {
  margin: 0;
}

.card .card-body {
  padding: 0.5rem 0.75rem;
}

.card {
  margin-top: 16px;
}

.employee-dates {
  display: flex;
}

.table-header-custom,
.general-info-details {
  display: flex;
}

.general-info-details {}

.employee-img-wrapper {
  margin-right: 30px;
  margin-bottom: -72px;
}

.w-65 {
  width: 65%;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-right-8 {
  padding-right: 8px;
}

.dataTables_length {
  padding-left: 24px;
}

.dataTables_length select {
  font-size: 14px;
}

.custom-pagination ul.pagination {
  float: right;
}

.pullright {
  float: right;
  align-content: end;
}

.custom-pagination ul.pagination .page-item.active .page-link {
  color: #fff !important;
  background-color: $primary-color !important;
  background: $primary-color !important;
  border: none;
  box-shadow: none;
}

.custom-pagination ul.pagination .page-link {
  color: $primary-color !important;
}

.main_pageing .paging_simple_numbers {
  padding-right: 24px;
}

.table-header-custom .extra-details {
  position: absolute;
  right: 15px;
  top: 15px;
}

.sml-txt {
  margin: 0 !important;
  font-size: 12px !important;
  line-height: 1.2em !important;
}

.sml-extra-heading {
  margin: 0 !important;
  line-height: 1em !important;
  // margin-top: 5px !important;
  font-size: 14px !important;
  line-height: 1.2em !important;
  word-break: break-word;
}

// css for tab and mobile

@media (max-width: 992px) {
  .top-bar {
    padding: 15px !important;
  }

  .top-bar .col-6 {
    padding: 0 !important;
  }

  .basic-table-layout table {
    margin-top: 0px !important;
  }

  header.header-6 .header-nav,
  .header-actions {
    display: none !important;
  }

  header.header-6 .navbar-light .navbar-toggler {
    border: none;
    font-size: 16px;
  }

  header.header-6 .navbar-light .navbar-toggler:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }

  header.header-6 .branding .nav-link {
    padding-left: 0px;
  }

  header.header-6 .branding {
    padding-left: 0;
  }

  header.header-6 #navbarNav .navbar-nav {
    display: block;
  }

  header.header-6 .navbar-nav li>a,
  header.header-6 .navbar-nav li>button {
    color: white !important;
    font-weight: 500;
  }

  header.header-6 .navbar-nav .dropdown button {
    margin-top: 0 !important;
    padding: 12px 0 !important;
  }

  header.header-6 .navbar-nav .dropdown .dropdown-menu {
    position: relative !important;
    transform: none !important;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  header.header-6 .navbar-nav .dropdown .dropdown-menu.is-off-screen {
    position: absolute !important;
  }

  header.header-6 .navbar-nav .dropdown .dropdown-menu a {
    color: white !important;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(256,256,256,1 )' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}

// Css only for mobile

@media screen and (max-width: 767px) {
  .basic-table-layout table {
    border: 0;
  }

  .basic-table-layout table caption {
    font-size: 1.3em;
  }

  .basic-table-layout table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .basic-table-layout table tr {
    border: 1px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .basic-table-layout table tbody td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    display: flow-root;
  }

  .basic-table-layout table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
  }

  .basic-table-layout table td:last-child {
    border-bottom: 0;
  }

  section {
    padding: 0 15px;
    position: relative;
  }

  .basic-table-layout h3 {
    padding: 15px 0;
  }

  .flex-revers {
    display: block;
    text-align: left;
  }

  .filter-drop-down .dropdown {
    width: 100%;
  }

  .filter-drop-down .dropdown button {
    width: 100%;
    margin-bottom: 15px;
  }

  .serach-box-wrapper .search-box {
    width: 100%;
  }

  .serach-box-wrapper .search-box input {
    width: 100%;
  }

  .dropdown button.dropdown-toggle:not(.btn).filter-dropdown {
    margin-left: 0;
    margin-right: 15px;
  }

  .basic-table-layout .btn-group-custom {
    text-align: left !important;
    margin-top: 15px;
  }

  .table-header-custom {
    padding: 15px;
  }

  .basic-table-layout table .clr-form-control {
    float: right;
    width: 50%;
  }

  .basic-table-layout table select,
  .basic-table-layout table input {
    height: auto;
    min-height: auto;
  }

  .table-header-custom {
    display: unset !important;
  }

  .basic-table-layout .table-caption {
    text-align: left;
  }

  .table-header-custom .user-profiler {
    width: 100%;
    margin: 0;
    margin-top: 0px;
  }

  .bottom-btn-list {
    padding: 0 15px;
  }

  .table-caption {
    padding: 0 15px;
  }

  .user-profiler {
    // margin-left: auto;
    margin-right: 0;
    width: fit-content;
    padding: 0;
  }

  .basic-table-layout table .clr-select-wrapper::after {
    top: 9px;
  }

  .basic-table-layout table textarea {
    width: 100% !important;
    max-width: inherit;
    min-width: auto;
  }

  .employee-img-wrapper {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .employee-img {
    display: block;
    margin: 0 auto;
  }

  .general-info {
    text-align: center;
  }

  .general-info h3 {
    padding-bottom: 0;
  }

  .general-info-details {
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .global-tabs .nav-tabs {
    padding-left: 0;
  }

  .global-tabs .nav .nav-link {
    font-size: 14px;
    background-color: transparent;
  }

  .padding-left-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .padding-right-0 {
    padding-left: 0;
    padding-right: 0;
  }


  .dataTables_length select {
    font-size: 14px;
  }

  .custom-pagination {
    padding-bottom: 30px;
  }

  .table-header-custom .extra-details {
    position: absolute;
    right: 15px;
    top: 0;
  }

  .basic-table-layout .table thead th:first-child,
  .basic-table-layout .table tbody tr td:first-child {
    padding: 0.4583333333rem 0.5rem;
  }

  .search-box input[type="text"] {
    border: 1px solid #949494 !important;
  }

  .pagination .pagination-page {
    display: none;
  }

  .profiler-content h5 {
    font-size: 12px !important;
  }

  .sml-extra-heading {
    font-size: 12px !important;
  }

  .profiler-content p {
    font-size: 10px !important;
  }

  .sml-txt {
    font-size: 10px !important;
  }

  .w-65 {
    width: 100%;
  }

  .global-tabs .nav {
    height: auto;
    padding-left: 15px;
  }

  .employee-img-wrapper {
    margin-right: 0;
  }

  // .bg-section {
  //   top: -30px;
  // }

  .heading-small-c {
    font-size: 12px;
  }

  .heading-small-high {
    font-size: 14px;
  }

  .general-info-details .sml-extra-heading {
    margin-top: 0;
  }
}

.check-sign-save {
  position: absolute;
  right: 35px;
  top: 9px;
  font-size: 18px;
  color: green;
}

.close-sign-undo {
  position: absolute;
  right: 10px;
  top: 9px;
  font-size: 18px;
  color: red;
}

.project-img {
  // max-height: 110px;
  box-shadow: 4px 4px 0px 0px rgb(0 0 0 / 10%);
  border-radius: 4px;
  margin: 0 auto;
  display: block;
  border: 1px solid #e6e6e6;
  height: 120px;
  width: 120px;
  z-index: 990;
  position: relative;
}

.pointer {
  cursor: pointer;
}

.permission-dropdown {
  position: relative;
  right: 0;
  top: 0;
}

.permission-dropdown select {
  padding: 5px 10px;
  font-size: inherit;
  min-width: 170px;
}

.auto-complete-list {
  position: absolute;
  width: 100%;
  z-index: 9999;
  background: white;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
}

.auto-complete-list ul {
  list-style: none;
}

.auto-complete-list ul li {
  padding: 8px 10px;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
}

.auto-complete-list ul li:hover {
  background: #f5f5f5;
}

.option-drop-down select {
  border: none;
}

.employess-permission-list ul {
  list-style: none;
}

.employess-permission-list ul li {
  padding: 8px 0;
}

@media (min-width: 767px) {
  .pl-d-0 {
    padding-left: 0;
  }
}

.general-info-details .user-profiler {
  margin-left: 0;
  padding: 0;
}

.icon-img {
  height: 20px;
  width: 20px;
}

.project-role {
  vertical-align: middle;
  align-items: center;
  display: flex;
  padding: 0;
}

.profiler-content .badge {
  color: #fff !important;
  margin-left: 5px;
}

.badge {
  font-size: small;
  padding: 10px;
  color: white !important;
}

@media (max-width: 767px) {
  .profiler-content .badge {
    display: block;
    width: max-content;
    margin-top: 5px;
    margin-left: 0;
  }
}

.header-nav .dropdown-item {
  padding: 0;
}

.header-nav .dropdown-item a {
  padding: 0.125rem .5rem;
  width: 100%;
  display: block;
  font-size: 16px;
  text-decoration: none;
}

.header-actions .dropdown-menu {
  right: 15px !important;
  width: fit-content;
  left: auto !important;
  top: 35px !important;
  transform: none !important;
}

.color-white {
  color: #fff !important;
}

.width-350px {
  width: 350px !important;
}

.date-picker-filter .clr-input-group {
  border: none;
  padding: 0;
  min-width: 100%;
  height: 35px;
}

.date-picker-filter .clr-input-group input {
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
}

.date-picker-filter .clr-input-group input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #000000;
  opacity: 1;
  /* Firefox */
}

.date-picker-filter .clr-input-group button {
  margin-left: -30px;
}

.date-picker-filter .clr-input-group button svg {
  color: #002538;
}

.date-picker-filter .clr-input-group button clr-icon {
  margin-bottom: -3px;
}

.dropdown-menu .btn,
.dropdown-menu .dropdown-item {
  outline: none;
}

.comment-display {
  padding-left: 24px;
}

@media (max-width: 767px) {
  .comment-display {
    padding: 0 15px;
  }
}

.employee-form {
  padding: 0 0;
}

.employee-form .employee-header-edit {
  padding: 0 24px;
}

@media (min-width: 767px) {
  .employee-form .emp-img {
    margin-right: 30px;
    padding: 15px;
  }
}

.employee-form label {
  line-height: 1;
}

.employee-form input,
.employee-form input:active,
.employee-form input:focus,
.employee-form input:focus-visible,
.employee-form input:-internal-autofill-selected {
  width: auto;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  height: 35px;
  min-height: 35px;
  min-width: 100px;
  font-size: 14px;
  width: 100%;
  outline: none;
}

.employee-form .clr-form-control {
  margin-top: 0;
}

.employee-form select {
  width: auto;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  height: 35px;
  min-height: 35px;
  min-width: 100px;
  font-size: 14px;
  width: 100%;
  outline: none;
}

.emp-img {
  position: relative;
  width: fit-content;
}

// .emp-img img {
//   width: 4rem;
// }

.edit-employee-img {
  position: absolute;
  right: 18px;
  top: 15px;
  cursor: pointer;
  z-index: 991;
}

@media (max-width: 767px) {
  .employee-header-edit .employee-img {
    margin-right: 30px;
  }

  .edit-employee-img {
    top: 5px;
    right: 35px;
  }

  .employee-header-edit .row {
    padding: 0 10px;
  }
}

.table td,
.table th {
  padding: 15px 5px;
}

// .basic-table-layout tbody tr td {
//   // min-width: 100px;
//   // max-width: 150px;
//   // width: 150px;
// }
@media (max-width: 1300px) {
  .basic-table-layout table thead th {
    font-size: 12px;
  }

  td .profiler-content h5 {
    font-size: 12px !important;
  }
}

// @media (max-width: 1400px) {
//   td .user-profiler div:first-child {
//     width: 40px;
//   }
//   td .user-profiler div.profiler-content {
//     width: 100px;
//   }
// }
// @media (max-width: 1300px) {
//   td .user-profiler div.profiler-content {
//     width: 80px;
//   }
// }
// @media (max-width: 1150px) {
//   td .user-profiler div.profiler-content {
//     width: 60px;
//   }
// }
@media (max-width: 767px) {
  .general-info-details>div {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }

  .general-info {
    padding: 0 15px;
  }
}

@media (min-width: 767px) {
  .general-info-details>div {
    padding-right: 20px;
    width: auto;
    padding-left: 0;
    margin-bottom: 10px;
  }

  .general-info-details>div.userprofileBadge {
    min-width: 200px;
    width: auto;
  }

  .general-info-details>div.userprofileBadge .user-profiler {
    margin-top: 5px;
  }

  .general-info-details>div.userprofileBadge .user-profiler .profiler-content {
    padding-right: 0 !important;
  }
}

@media (max-width: 1300px) and (min-width: 768px) {
  .general-info-details>div {
    width: 200px;
  }
}

@media (min-width: 1386px) {
  .general-info-details>div {
    padding-right: 40px;
  }
}

input,
select,
textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  min-height: 32px;
  line-height: 1;
}

.date-picker-filter .clr-form-control,
.datatable-header .clr-form-control {
  margin-top: 0;
}

.table-header-custom {
  padding: 15px;
  padding-bottom: 10px;
  overflow: inherit;
}

// clr-step-content .clr-form-control input, clr-step-content .clr-form-control select{
//   width: 100%;
//   border: none;
//   border-bottom: 1px solid #999;
//   border-radius: 0;
//   padding: 0 .25rem;
// }
select.clr-input {
  -webkit-appearance: auto;
}

.clr-accordion-panel {
  margin-top: 30px;
}

.clr-accordion-header,
.clr-accordion-header:hover,
.clr-accordion-panel-open .clr-accordion-header {
  background: #53565A;
  color: white;
}

.clr-accordion-header {
  border-radius: 0.125rem;
}

.clr-accordion-panel-open .clr-accordion-header {
  border-radius: 0.125rem 0.125rem 0 0;
}

.clr-accordion-header button {
  color: white;
}

.clr-accordion-header button clr-icon {
  color: white;
}

.clr-accordion-header button .clr-accordion-status {
  margin-top: 2px;
  display: none;
}

.clr-accordion-inner-content {
  padding: 15px !important;
}

.clr-accordion-content {
  border-bottom: 1px solid #ccc;
}

.sml-lable-heading {
  display: block !important;
  color: #454545 !important;
  font-size: 11px !important;
  // font-weight: 600 !important;
  line-height: 1.2rem !important;
  margin: 0 !important;
}

.clr-accordion-inner-content .clr-form-control,
.clr-accordion-inner-content .clr-form-control .clr-textarea-wrapper {
  margin-top: 0;
}

.clr-control-label {
  line-height: 1.2rem;
  margin-bottom: 5px;
}

.clr-accordion-content {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0 0 6px 6px;
  border: 1px solid #d7d7d7;
  overflow: unset;
}

.seprator {
  height: 1px;
  background: rgba(0, 0, 0, 0.5);
  margin-top: 21px;
}

.table.table-vertical td,
.table.table-vertical th {
  width: 200px;
}

@media (max-width: 767px) {
  .m-p-12px {
    padding-top: 12px;
  }

  .table.table-vertical td,
  .table.table-vertical th {
    width: 120px;
  }

  .overflow-x-scroll-mini {
    overflow-x: scroll;
  }
}

.highlight-red {
  font-style: italic;
  color: red !important;
}

.table-footer tr:last-child {
  background: #e5e5e5 !important;
}

.table-footer tr:last-child td {
  font-weight: 700;
}

.vertical-tab-custom {
  margin-top: 30px;
}

.vertical-tab-custom .tab-content,
.vertical-tab-custom .nav {
  padding: 0;
}

.vertical-tab-custom .tab-content {
  width: 100%;
}

.vertical-tab-custom .clr-accordion-panel:first-child {
  margin-top: 0;
}

.vertical-tab-custom ul.nav {
  // box-shadow: 0 0.125rem 0 0 #d7d7d7;
  border-radius: 0.125rem;
  border: 1px solid #d7d7d7;
  background-color: #fff;
  min-width: fit-content !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.vertical-tab-custom ul.nav li {
  margin-right: 0 !important;
}

.datagrid-host {
  display: grid;
}

@media (max-width: 1200px) {
  .md-p-12px {
    padding-top: 12px;
  }
}

@media (max-width: 1600px) {
  .clr-accordion-panel-inactive>div {
    display: grid;
  }
}

.highlighted-badge {
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 5px;
  word-break: break-all;
  margin-bottom: 5px;
  display: inline-block;
}

.extended-table .accordion-button {
  -webkit-appearance: none;
}

.extended-table .accordion-collapse thead th {
  visibility: hidden;
  height: 0;
  max-height: 0;
  padding-top: 0;
  line-height: 0;
  border-top: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.extended-table .accordion-collapse table {
  border: none;
}

.cell-mini-text {
  font-size: 10px;
  display: block;
}

.extended-table td,
.extended-table th {
  text-align: left;
  vertical-align: middle;
}

@media (min-width: 767px) {

  .extended-table td,
  .extended-table th {
    max-width: 0px;
  }
}

.toggle-class {
  width: 25px;
  min-width: 25px;
}

// .extended-table td:first-child, .extended-table th:first-child{
//   padding-left: 40px;
// }
.extended-table .accordion-button.toggle-class,
.toggle-class {
  position: relative;
  min-width: 50px;
  width: 50px;
  height: 100%;
  display: table-cell;
}

.extended-table .accordion-button.toggle-class:before {
  content: "\f106";
  font-family: "FontAwesome";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  padding: 6px 8px;
  background: rgba(83, 86, 90, .20);
  border-radius: 50%;
}

.extended-table .accordion-button.collapsed.toggle-class:before {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.extended-table td .clr-control-label {
  margin-bottom: 0;
}

.badge.badge-in-table {
  border: 1px solid;
  padding: 5px;
  background: #fafafa;
  line-height: 1.2em;
}

.clr-accordion-header-button {
  border-radius: 4px;
  // flex-direction: row-reverse;
  justify-content: space-between;
}

.clr-accordion:not(.clr-stepper-forms) .clr-accordion-status cds-icon,
.clr-accordion:not(.clr-stepper-forms) .clr-accordion-status clr-icon {
  background: rgba(255, 255, 255, .37);
  border-radius: 50%;
  padding: 2px;
  height: 1.2rem;
  width: 1.2rem;
}

@media (max-width: 992px) {
  .scrll-md-table {
    overflow-x: scroll;
  }
}

.expandble-header {
  background-color: #f5f5f5;
  font-weight: 500;
  font-size: 14px !important;
}

.fix-height-table {
  max-height: 500px;
  overflow-y: auto;
}

.p-15px {
  padding: 15px;
}

.min-w-20px {
  min-width: 20px;
}

@media (max-width: 992px) {
  .min-w-20px {
    min-width: 150px;
  }
}

clr-stepper-panel .clr-accordion-header-button {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

clr-stepper-panel .clr-accordion-status {
  line-height: 1.5em;
}

clr-stepper-panel clr-icon {
  vertical-align: middle !important;
}

.stepper-component {
  position: relative;
  z-index: 99;
  padding: 0 15px;
  padding-bottom: 15px;
}

.stepper-component input,
.stepper-component select {
  width: 100%;
  font-size: 12px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
  min-height: 32px !important;
  line-height: 1 !important;
}

.clr-step-button {
  border-color: $primary-color;
  background-color: $primary-color;
  color: #fff;
}

.clr-step-button:hover {
  outline: none;
  background: transparent;
  color: #002538;
}

.input-des {
  font-size: 11px;
  line-height: 1.2em;
}

.stepper-component clr-input-container {
  // margin-top: auto !important;
  margin-bottom: 0;
}

.stepper-component .clr-accordion-complete-icon {
  display: none;
}

@media (max-width: 767px) {
  .pt-12px {
    padding-top: 12px;
  }
}

.stepper-component .clr-accordion-header {
  box-shadow: none !important;
}

// .stepper-component .clr-error .clr-subtext {
// position: absolute;
// bottom: -13px;
// }
.stepper-component .btn {
  margin-right: 0;
}

.stepper-process-bar ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.stepper-process-bar ul li {
  padding-top: 30px;
  position: relative;
  text-align: center;
}

.stepper-process-bar ul li::before {
  content: "";
  height: 25px;
  width: 25px;
  display: block;
  border: 1px solid;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #fff;
  z-index: 9;
}

.stepper-process-bar ul li:after {
  content: "";
  height: 20px;
  width: 20px;
  display: block;
  background: #fff;
  border-radius: 50%;
  top: 2.5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}

.stepper-process-bar ul li.active:after {
  background: $primary-color;
}

.stepper-process-bar ul li.completed:after {
  background: #c5c5c5;
  content: "\f00c";
  font-family: "FontAwesome";
  font-weight: 900;
  line-height: 20px;
}

.stepper-process-bar ul li a {
  text-decoration: none !important;
}

.stepper-process-bar ul:before {
  content: "";
  height: 1px;
  background: #002538;
  display: block;
  width: 100%;
  position: absolute;
  top: 12.5px;
  z-index: 1;
}

.stepper-process-bar-wrapper {
  position: relative;
  margin: 0 15px;
}

.stepper-process-bar {
  margin-top: 16px;
}

.stepper-component .clr-control-container {
  position: relative;
}

@media (max-width: 767px) {
  .stepper-process-bar ul {
    overflow-x: scroll;
    padding-bottom: 5px;
    justify-content: flex-start;
  }

  .stepper-process-bar ul li {
    min-width: 110px;
  }
}

header.header-6 .branding {
  display: flex;
  align-items: center;
  gap: .5rem;
  // padding-left: 12px;
}

.clr-accordion-header-button .clr-accordion-status {
  color: #fff !important;
  line-height: 1.8em;
}

.p15px {
  padding-left: 15px;
  padding-right: 15px;
}

.p20px {
  padding-left: 20px;
  padding-right: 20px;
}

.basic-table-layout .clr-control-container {
  width: 100%;
}

.item-name {
  display: flex;
  align-items: center;
}

.list-group-item.active {
  z-index: 2;
  color: #fff !important;
  background-color: #002538;
  border-color: #002538;

  h5 {
    color: #fff !important;
  }
}

.ml-auto {
  margin-left: auto !important;
}

.accordion-button::after {
  background-image: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.ta-results {
  padding: 0;
  position: relative !important;
}

.sml-extra-heading {
  >section.ta-results.list-group {
    max-height: 200px;
    overflow-y: auto;
  }
}

.edit-task-box {
  z-index: 999;
  background: #fff !important;
  padding: 0 !important;
  height: 100%;
  overflow-y: scroll;
}

.side-box {
  position: fixed;
  top: 70px;
  right: -20rem;
  width: 20rem;
  height: calc(100% - 80px);
  transition: linear all 0.5s;
  z-index: 99999;
  padding: 0 !important;
}

.sidebox-show {
  right: 0rem !important;
}

.clr-accordion-panel-complete .clr-accordion-complete-icon,
.clr-accordion-panel-error .clr-accordion-error-icon {
  position: absolute;
  right: 15px;
  top: 6px;
}

.clr-stepper-forms .clr-accordion-status {
  min-width: auto !important;
  width: auto !important;
  margin-right: 10px;
}

.clr-stepper-forms .clr-accordion-number {
  display: none;
}

.borderLess {
  border: none !important;
}

.stepper-component table {
  border: 1px solid;
  border-bottom: none;
}

.stepper-component table thead th {
  color: var(--clr-thead-color, #666666);
  background-color: var(--clr-thead-bgcolor, #fafafa);
  border-bottom: 1px solid;
}

.col-md-1-0 {
  width: 7.333% !important;
}

.margin-top-18 {
  margin-top: 1.8rem;
}

.w-95 {
  width: 95%;
  margin-right: 15px;
}

.pop-up-filter {
  position: absolute;
  background: white;
  padding: 20px;
  min-width: 200px;
  right: 0;
  background-color: var(--clr-card-bg-color, white);
  box-shadow: 0 0.15rem 0 0 var(--clr-card-box-shadow-color);
  border: 1px solid #dedede;
  display: none;
  top: 40px;
  z-index: 999;
}

.pop-up-filter.show {
  display: block;
}

.btn-theme {
  color: #753BBD !important;
  border: 1px solid !important;
  background: #fff !important;
  background-color: #fff !important;
  box-shadow: none !important;
  outline: none !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.w-sign {
  display: inline-block;
  position: relative;
  width: fit-content;
}

.w-sign input {
  max-width: 90px;
  padding-right: 20px;
}

.w-sign .input-right-align {
  max-width: 20px;
  padding-right: 25px;
  // .w-sign input {
  //   max-width: 150px;
  //   padding-right: 20px;
  // }
}

.w-sign .input-right-align {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.w-i-container.w-sign .input-right-align {
  transform: none;
  top: auto;
  bottom: 4px;
  left: 24px;
  right: 0;
}

.w-i-container.w-sign input {
  max-width: 100%;
}

.w-i-container.w-sign {
  width: 100%;
}

.vert-middle-column {
  th {
    vertical-align: middle;
  }
}

.vert-middle-column tr td {
  vertical-align: middle;
  align-items: center;

  .w-sign {
    .input-right-align {
      transform: none;
      top: 10px;
      bottom: auto;
      left: 10px;
      right: 0;
    }
  }
}

.clr-input-wrapper {
  max-height: fit-content;
}

.custom-design.clr-input {
  font-size: 12px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
  padding: 5px 10px !important;
  min-height: 32px !important;
  line-height: 1 !important;
}

@media (max-width: 1200px) {
  .scrll-tbl {
    overflow-x: scroll;
  }
}

.scrll-tbl-all {
  overflow-x: scroll;
}

@media (min-width: 768px) {

  .extended-table thead th,
  .expendable-rows thead th {
    min-width: 50px;
  }
}

@media (max-width: 992px) {
  .overflow-x-scroll-mini {
    overflow-x: scroll;
  }
}

@media (max-width: 1300px) {
  .tabs-vertical>.nav {
    width: auto;
    min-width: 160px;
  }
}

// .extended-table .user-profiler{
//   min-width: max-content;
// }

table.extended-table,
table.extended-table table {
  table-layout: inherit;
}

table.extended-table {
  table-layout: unset;
  min-width: 1580px;
}

table.extended-table .col-staff {
  width: 180px;
  max-width: initial;
}

.fix-height-table {
  overflow-x: scroll;
}

@media (max-width: 1300px) {
  .table.extended-table {
    overflow-x: scroll;
  }
}

.hover-underline {
  cursor: pointer;
  border-bottom: 3px solid transparent;
}

.hover-underline:hover {
  cursor: pointer;
  border-bottom: 3px solid rgb(0, 37, 56);
}

// =========================================staffList====================
.profiler-content {
  position: relative;
  padding-right: 25px !important;
  text-align: left;
}

.list-view-btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  &:hover {
    cursor: pointer;
  }
}

.user-profiler {
  position: relative;
}

.list-view-cnt {
  position: absolute;
  background: white;
  padding: 0;
  min-width: 250px;
  right: 0;
  background-color: white;
  background-color: var(--clr-card-bg-color, white);
  box-shadow: 0 0.15rem 0 0 var(--clr-card-box-shadow-color);
  border: 1px solid #dedede;
  display: none;
  top: 50px;
  z-index: 9999;
  left: 15px;
  max-width: 250px;
}

.list-view-cnt.show {
  display: block;
}

.list-view-cnt .list-input {
  padding: 15px;
}

.list-view-cnt .list-group button {
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.list-view-cnt .list-group button:last-child {
  border-bottom: none;
}

.list-view-cnt .list-group button h5 {
  font-size: 12px !important;
  margin-top: 0 !important;
}

// =========================================staffList = END ====================

.vert-border td {
  border-right: 2px solid !important;
  border-color: inherit !important;
}

.vert-border td:last-child {
  border-right: none !important;
}

.edit-task .form-control {
  font-size: 14px;
  padding: 5px 10px;
}

/* 9 dec */

.fee-distribution {
  tbody {
    tr {
      .button-wrapper {
        display: none;
        position: absolute;
        bottom: -22px;
        background: white;
        box-shadow: 0 3px 4px 1px rgb(0 0 0 / 8%);
        padding: 0px 10px;
        z-index: 9;
        width: auto;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;

        &:nth-child(even) {
          background-color: #f5f5f5;
        }

        .add-icon-row {
          height: auto;
          outline: none;
          box-shadow: none;
        }

        button {
          &:hover {
            //background-color: transparent;
          }
        }
      }

      &:hover {
        .button-wrapper {
          display: flex;
        }
      }

      th {
        font-weight: 500;
        font-size: 14px;
        text-align: left;
      }

      th,
      td {
        // .show-hover-icon{
        //   opacity: 0;
        //   &:hover{
        //     opacity: 1;
        //   }
        // }
        width: 25%;
        position: relative;

        // &:first-child{
        //   width: 6%;
        // }
        .w-sign {
          width: 100%;
          max-width: 113px;

          input {
            max-width: 100%;
            padding-right: 0px;
            font-weight: 500;
          }
        }

        input {
          min-width: 113px;
        }

        input[type="radio"] {
          min-width: auto;
        }

        select {
          min-width: 113px;
        }

        .clr-error .clr-validate-icon {
          margin-left: -1.2rem;
        }

        .amount-value {
          // max-width:200px;
          border: 1px solid rgba(0, 0, 0, 0.5);

          &:first-child {
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
          }

          &:last-child {
            border-bottom-left-radius: 0px !important;
            border-top-left-radius: 0px !important;
          }

          input {
            border: 0px !important;
            border-radius: 4px !important;
          }

          border-radius: 4px !important;
        }
      }
    }
  }
}

.net-fee-last {
  margin: 0 !important;
  margin-right: 75px !important;
}

.complexity-label {
  label {
    margin-right: 15px;
    font-weight: 500;
    color: var(--clr-thead-color, #666666);
    width: 25%;
  }

  .clr-form-control {
    width: 100%;
  }
}

.table-view {
  width: 100%;

  .table-body {

    .row {
      position: relative;

      .clr-form-control {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &:hover {
        .button-wrapper {
          display: flex;
        }
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: start;
      background: #fff;
      padding: 0 10px;
      z-index: 9;
      width: auto;
      box-sizing: border-box;

      .add-icon-row {
        height: auto;
        outline: none;
        box-shadow: none;
        background: none;
      }
    }
  }

  .headers {
    .header-box {
      background-color: var(--clr-thead-bgcolor, #fafafa);
      border-bottom: 1px solid #d7d7d7;
      font-weight: 500;

      text-align: left;

      label {
        font-size: 14px;
        padding: 10px 5px;
        margin: 0;
        line-height: normal;
        color: var(--clr-thead-color, #666);
      }
    }
  }
}

.total-footer {
  border-top: 0;
  border: 1px solid #d7d7d7 !important;

  tr {
    td {
      background: #e5e5e5;
      border: 0px;
    }
  }
}

.border-table-0 {
  border: 1px solid #d7d7d7 !important;

  tr {
    th {
      border: 0;
      border-bottom: 1px solid #d7d7d7 !important;
      font-weight: 500;
      font-size: 13px;
      text-align: left;
    }

    td {
      border: 0px;
    }
  }
}

.datatable-header {
  z-index: 1;
}

.w-sign .input-right-align {
  padding-right: 2px;
}

.table-genral {
  thead {
    th {
      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 15%;
      }
    }
  }

  tbody {
    tr {
      td {
        &:nth-child(1) {
          width: 20%;
        }

        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 15%;
        }

        .w-sign {
          input {
            width: 100%;
          }

          .input-right-align {
            text-align: left;
          }
        }

        //  .service{
        //   white-space: nowrap;
        //   flex-wrap: wrap;
        //   display: flex;
        //   justify-content: flex-end;
        //   >div{
        //     margin-right: 8px;
        //   }
        //  }
      }
    }
  }
}

.scrll-tbl-all {
  overflow-x: auto;
}

.table-layout-fixed {
  table-layout: fixed;
}

.total-bar {
  td {
    background: #e5e5e5;
    border: 0px;
    vertical-align: top !important;

    b {
      font-weight: 700 !important;
    }
  }
}

.service {
  white-space: nowrap;
  flex-wrap: wrap;
  display: flex;

  // justify-content: flex-end;
  >div {
    margin-right: 8px;
  }
}

input[type="text"] {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 5px 10px;
  min-height: 32px;
  line-height: 1;
}

.table-strip {
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
  }
}

table tbody tr .button-wrapper {
  display: none;
  position: absolute;
  bottom: -22px;
  background: white;
  box-shadow: 0 3px 4px 1px rgb(0 0 0 / 8%);
  padding: 0px 10px;
  z-index: 9;
  width: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
}

table tbody tr:hover .button-wrapper {
  display: flex;
}

.select>.clr-control-container {
  width: 100%;
}

.table {
  tr {
    td {
      .clr-control-container {
        width: 100%;

        .clr-input-wrapper {
          width: 100%;
        }
      }
    }
  }

  .input-100 {

    .clr-control-container,
    .clr-input-wrapper,
    input,
    select {
      width: 100%;
    }
  }
}

.list-view-cnt {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;

  .list-group-item {
    padding: 15px 0;
  }
}

// #accordionExample {
.user-profiler {
  display: flex;
  flex-wrap: nowrap;

  .list-view-cnt {
    top: 40px;
  }
}

// }

.table td,
.table th {
  padding: 20px 15px;
}

textarea {
  resize: none !important;
}

.client-info {
  .clr-input-container {
    .clr-control-container {
      .clr-input-wrapper {
        width: 100%;
      }
    }
  }
}

.datagrid-host {
  .clr-validate-icon {
    display: none;
  }
}

.tb-ly-fixed {
  table-layout: fixed;
}

.w-mng td {
  width: 33.33% !important;
}

@media (min-width: 1200px) {
  .tb-ly-fixed-lg {
    table-layout: fixed;
  }
}

@media (max-width: 1200px) {
  .w-mng td {
    width: 100% !important;
    display: block;
    padding: 0 !important;
  }

  .w-mng td input {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .w-mng td:last-child input {
    margin-bottom: 0;
  }

  .padding-top-10px {
    padding-top: 10px;
  }
}

.mat-chip,
.mat-option-text {
  font-size: 12px;
}

.mat-form-field-infix {
  border-top: none;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  // position: relative;
  border: none;
}

.mat-chip.mat-standard-chip .mat-chip-remove .mat-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  font-size: 20px;
  transform: translateY(-50%);
  height: auto;
  width: auto;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
  border-radius: 4px !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-standard-chip.mat-chip-with-trailing-icon {
  padding: 5px;
  height: auto;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before,
.mat-form-field-appearance-fill .mat-form-field-ripple,
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: transparent;
}

.mat-chip-list:focus-visible {
  outline: none;
}

.pr-2 {
  padding-right: 10px !important;
}

.pr-5px {
  padding-right: 5px !important;
}

.multiple-profile {
  display: block;
}

.d-flex-c {
  display: flex;
  flex-wrap: wrap;
}

.multiple-profile span {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 4px;
  background: #f5f5f5;
  border-radius: 4px;
  display: block;
}

table.w-border tbody tr:nth-child(even) {
  background-color: transparent !important;
}

@media (max-width: 1920px) {
  .overflow-x-scroll-desktop {
    overflow-x: auto;
  }
}

.datatable-header .datatable-cell {
  position: relative;
}

.datatable-header .datatable-cell .dropdown-menu {
  transform: none !important;
  left: 0 !important;
  top: auto !important;
}

.overflow-int .clr-accordion-content {
  overflow: inherit;
}

.overflow-vis .clr-accordion-content {
  overflow: visible;
}

.mat-standard-chip {
  border-radius: 5px !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #e0e0e0 !important;
  background: #e0e0e0 !important;
  color: #002538 !important;
  padding: 4px 8px !important;
  margin: 3px !important;
  font-weight: 600;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
  color: rgba(0, 0, 0, 0.733) !important;
}

.clr-input:disabled {
  color: #6b6b6b !important;
}

.edit-emp-img {
  max-width: 120px;
}

.employee-form .clr-control-container {
  width: 100%;
}

.date-box .clr-control-container {
  width: 100%;
  padding: 0;
  height: auto;
  max-height: fit-content;
  border: none;
}

.setting-page .tab-content {
  // width: calc(100% - 12rem - 45px);
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  padding-right: 15px;
}

.setting-page .tab-content .dataTables_length {
  padding-left: 0;
}

.setting-page .tab-content .main_pageing .paging_simple_numbers {
  padding-right: 0;
}

.setting-page .search-box {
  margin: 0 -15px;
}

.company-list-view-cnt {
  padding: 20px !important;
  left: 0;
}

.company-position {
  position: relative !important;
}

.reset-form-btn {
  &:hover {
    cursor: pointer;
  }
}

.required {
  color: red !important;
  padding-left: 2px !important;
}

.clientform-accordian .clr-control-container .clr-select {
  border: none !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 4px !important;
}

.task-table table tr td {
  min-width: auto;
  padding: 10px 10px;
}

.task-table table tr td.role-task {
  min-width: 25px;
}

// .task-table table tr td.col-staff-min-220{
//   min-width: 230px;
//   width: 230px;
// }

.task-table table tr th {
  padding: 10px 10px;
  font-size: 14px;
}

.task-table table tr td:first-child {
  min-width: 40px !important;
  width: 40px !important;
}

.task-table table tr td:nth-child(2) {
  // min-width: 70px;
  min-width: 70px;
  max-width: 70px;
  width: 70px;
}

.extended-table .accordion-button.toggle-class,
.toggle-class.accordion-button:not(.collapsed) {
  background-color: transparent;
  box-shadow: none;
}

.dashboard-table table>tbody:nth-child(odd)>tr.expandble-header:not(.active-panel)>td {
  background-color: #F8F5FC;
}

.task-table table tr td .add-icon-row {
  margin: 0;
  height: auto;
}

.hover-underline {
  border-bottom: 3px solid transparent !important;
}

.hover-underline:hover {
  border-bottom: 3px solid #002538 !important;
}

.expandble-header.active-panel {
  background-color: #828282 !important;
}

.expandble-header.active-panel * {
  color: #fff !important;
}

.expendable-rows tbody:nth-child(odd) tr {
  background-color: #f5f5f5;
}

.list-view-cnt .ta-results .profiler-content h5 b,
.list-view-cnt .ta-results .profiler-content p {
  color: #002538 !important;
}

.list-view-cnt input {
  color: #000 !important;
}

.setting-partner-list .datatable-cell {
  word-wrap: break-word !important;
}

.state-license .clr-accordion-panel-inactive>div {
  overflow: hidden;
}

.default-table thead th {
  background-color: var(--clr-thead-bgcolor, #fafafa) !important;
  color: var(--clr-thead-color, #666666) !important;
}

.task-table table>tbody:nth-child(even)>tr.expandble-header:not(.active-panel)>td {
  background-color: #fff;
}

.common-hyperlink-remove-style {
  text-decoration: none !important;
  color: inherit !important;
}

.tooltip-add-opacity {
  opacity: 1 !important;
}

.tooltip-content-color {
  color: white !important;
  width: 15rem !important;
}

.info-symbol-space {
  margin-left: 2px;
}

.tooltip-add-opacity path {
  color: #007ccb;
}

.symbol-align svg {
  vertical-align: baseline;
}

.custom-col-fil .multiselect-dropdown {
  font-size: 14px !important;

  .dropdown-btn {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .dropdown-btn .selected-item {
    white-space: break-spaces;
    padding: 0 5px !important;
    font-weight: 500 !important;
    margin: 3px !important;
  }

  .dropdown-btn .dropdown-multiselect__caret {
    line-height: normal !important;
    height: 33px !important;
  }

  .dropdown-btn>span {
    padding-right: 15px !important;
  }
}

.btn-border:hover {
  border: solid 1px !important;
}

.custom-field-error-message {
  font-size: 11px;
  color: red !important;
  float: left;
}

.content-align-spacebt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  flex-wrap: wrap;
}

.user-profile-bid .user-profiler,
.user-profile-padding .user-profiler {
  padding-left: 15px;
}

.user-profile-padding {
  overflow: unset;
}

.pt-3-2 {
  padding-top: 0.8rem !important;
}

.custom-pagination {
  padding-bottom: 15px;
}

@media (max-width: 767px) {

  .user-profile-bid .list-view-cnt,
  .user-profile-padding .list-view-cnt {
    right: auto;
    left: 15px;
  }

  .mobile-table-edit-view.client_information_table tr th,
  .mobile-table-edit-view tr th {
    background: none !important;
    border: none !important;
    width: 100% !important;
  }

  .mobile-table-edit-view.client_information_table tr td,
  .mobile-table-edit-view tr td {
    // padding: 0 !important;
    // padding-top: 8px !important;
    border: none !important;
  }

  .mobile-table-edit-view.client_information_table,
  .mobile-table-edit-view {
    border: 1px solid rgb(0, 37, 56);
  }

  .mobile-table-edit-view.client_information_table tr:nth-child(even),
  .mobile-table-edit-view tr:nth-child(even) {
    background-color: rgb(250, 250, 250);
    margin-bottom: 0;
  }

  .mobile-table-edit-view.client_information_table tr:nth-child(odd),
  .mobile-table-edit-view tr:nth-child(odd) {
    background-color: #fff;
    margin-bottom: 0;
  }

  .mobile-table-edit-view.client_information_table tr td,
  .mobile-table-edit-view tr td,
  .mobile-table-edit-view.client_information_table tr td .clr-form-control,
  .mobile-table-edit-view tr td .clr-form-control {
    width: 100%;
  }

  .pt-0-mobile {
    padding-top: 0 !important;
  }

  .vertical-tab-custom ul.nav {
    margin-right: 10px;
  }

  .company-position {
    padding-right: 35px;
  }

  .fee-distribution tbody tr th,
  .fee-distribution tbody tr td {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .dataTables_length {
    text-align: left;
    padding-left: 15px;
    padding-top: 15px;
  }

  .custom-pagination ul.pagination {
    float: unset;
    padding-left: 15px;
    padding-top: 10px;
  }

  .table-multiple-input input {
    min-width: 130px;
  }
}

@media (max-width: 1200px) {
  .text-right-1200 {
    text-align: right;
  }
}

.mw-auto {
  min-width: auto !important;
}

.post-split-table .w-sign {
  max-width: 113px;
}

.table-genral tbody tr td .w-sign input {
  padding-left: 23px;
}

table .center {
  text-align: center !important;
}

.scroll-section {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 30px);
}

.advance-filter-content .filter-label {
  white-space: normal;
}

.no-space-panel .nav {
  margin-right: 0 !important;
}

@media (max-width: 480px) {
  .content-align-spacebt .d-flex {
    margin-top: 15px;
  }
}

.custom-overflow-wrap {
  word-break: break-all !important;
  white-space: break-spaces !important;
  height: auto !important;
}

.max-w-100 {
  max-width: 100% !important;
}

// .dropdown-toggle::after {
//   display: none;
// }
header.header-6 .header-actions button.dropdown-toggle:not(.btn) {
  padding-right: 12px;
  margin-right: 14px;
}

.header-actions button.dropdown-toggle:not(.btn):after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: inline-block;
  position: absolute;
  content: "";
  background: var(--clr-header-font-color, #fafafa);
  opacity: var(--clr-header-divider-opacity, 0.15);
  opacity: 0.15;
  height: 2rem;
  width: var(--clr-global-borderwidth, 0.06rem);
  top: 21px;
  left: auto;
  right: 0;
  background: none;
  opacity: 1;
}

.application-roles-overflow .clr-accordion-content {
  overflow: unset !important;
}

.custom-dropdown-arrow::after {
  display: none;
}

.datatable-cell .user-profiler {
  flex-wrap: inherit;
  padding-left: 15px;
}

.datatable-cell .profiler-content h5 {
  margin-top: 0 !important;
}

.div_work_address {
  margin-top: 10px !important;

  .checkbox_work_address {
    margin: 5px !important;
    height: unset !important;
    min-height: unset !important;
    min-width: unset !important;
    width: unset !important;
  }
}

.dropdown-menu.performance-additional-filter {
  left: -65px !important;
  transform: none !important;
  top: 40px !important;
}

.performance-additional-dropdown {
  position: relative !important;
}

@media (min-width: 767px) {
  .padding-right-15px {
    padding-right: 15px;
  }
}

// .clr-accordion-panel-open .clr-accordion-content{
//   overflow: inherit;
// }
.basic-table-layout-default {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  position: relative;
  z-index: 999;
  margin-top: 30px;
  // box-shadow: 0px 4px 0px 0px rgb(0 0 0 / 10%);
  border: none;
}

@media screen and (max-width: 767px) {
  .basic-table-layout-default h3 {
    padding: 15px 0;
  }
}

.add-project-complexcity-table tr td:first-child {
  min-width: 300px;
}

.multiselect-dropdown .dropdown-list {
  margin-top: 0;
}

@media (max-width: 767px) {
  .side-box {
    width: 15rem;
  }
}

@media (max-width: 400px) {

  .tabs-vertical .tab-content .text-right .btn-primary .btn,
  .tabs-vertical .tab-content .btn.btn-primary {
    display: block;
    margin-right: 0;
    margin-left: auto;
  }

  .tabs-vertical .tab-content .btn-primary .btn,
  .tabs-vertical .tab-content .btn.btn-primary {
    display: block;
  }

  .additonal-filter-btn button.btn.btn-primary {
    display: inherit;
  }

  .table-header-custom .content-align-spacebt .d-flex {
    flex-wrap: wrap;
  }

  .table-header-custom .content-align-spacebt .d-flex app-additional-filter button {
    margin-bottom: 10px;
  }
}

.w-100 .clr-control-container {
  width: 100% !important;
}

.dashborad-layout {
  z-index: 999;
  position: relative;
  margin-top: 30px;
}

.padding-20px {
  padding: 20px;
}

.bottom-card-text {
  font-size: 14px;
  color: #666666 !important;
}

.font-weight-800 {
  font-weight: 0 !important;
}

.card-right-img {
  position: absolute;
  right: 20px;
  height: 45px;
}

.extended-table-color-diff thead th {
  color: var(--clr-thead-color, #666666);
  padding: 10px 10px;
  font-size: 14px;
}

.card-box-shadow {
  box-shadow: 4px 4px 4px -2px #dedede !important;
}

.icon-fix .clr-error .clr-validate-icon {
  margin-left: -0.2rem !important;
}

.taskListHeader_Hide {
  th {
    visibility: hidden !important;
    height: 0 !important;
    padding: 0 !important;
  }
}

.roleListHeader_show {
  position: sticky !important;
  top: 0 !important;
  background-color: #fff;
  z-index: 99 !important;

  th {
    visibility: visible !important;
    height: 40px !important;
    padding-top: 10px !important;
  }
}

.dashboard-tasks {
  font-weight: 400 !important;
}

table tbody tr .dashboard-button-wrapper {
  left: 20;
  height: 26px;
  display: none;
  position: absolute;
  background: white;
  box-shadow: 3px 4px 1px rgb(0 0 0 / 8%);
  bottom: 5px;
  transform: translateY(25px);
  right: 0;
  padding: 0px 5px;
  z-index: 9;
  width: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
}

.dashboard-table .dashboard-button-wrapper button {
  height: 26px;
  width: 26px;
}

.dashboard-table .dashboard-button-wrapper button:hover {
  background-color: transparent !important;
}

// table tbody tr:hover .dashboard-button-wrapper {
//     display: flex;
// }
.dashboard-tr-position tr {
  position: relative;
}

table tbody tr.parent-row:hover>td .dashboard-button-wrapper {
  display: flex;
}

table tbody tr.child-row:hover>td .dashboard-button-wrapper {
  display: flex;
}

.dashboard-table table>tbody:nth-child(even)>tr.expandble-header:not(.active-panel)>td {
  background-color: #fff;
}

.dashboard-table table tr td {
  padding: 10px 15px;
}

.dashboard-table table>thead tr th {
  padding: 10px 15px;
  font-size: 14px;
}

.dashboard-table .btn-set-margin {
  margin-right: 0px;
  margin-top: 2px;
}

.dashboard-table .add-edit-btn-position-edit {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(25px);
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  box-shadow: 3px 4px 1px rgb(0 0 0 / 8%);
  z-index: 9;
}

.dashboard-table .add-edit-btn-position-edit button {
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.expandble-header .active-panel .dashboard-button-wrapper {
  background: #828282 !important;
}

.disable-edit-button {
  display: none !important;
}

.expandble-header.active-panel input[type="number"] {
  color: black !important;
}

.expandble-header .active-panel .add-edit-btn-position-edit {
  background: #828282;
}

.expandble-header .active-panel .btn.btn-primary-outline:hover {
  background-color: transparent !important;
  color: black !important;
}

.remove-assignee {
  background-color: #753BBD;
  line-height: 2;
  border-radius: 4px;
  color: white;
}

.custom-add-dollar-symbol {
  position: absolute;
  right: 10px !important;
  top: 50%;
  transform: translateY(-50%);
  max-width: 20px;
}

.remove-assignee {
  background-color: #753BBD;
  line-height: 2;
  border-radius: 4px;
  color: white;
}

.list-view-btn {
  right: 0;
}

.profiler-content {
  position: relative;
  padding-right: 0px !important;

  b {
    font-weight: bolder;
    display: inline-block;
    overflow: hidden;
    font-size: 13px;
  }
}

// tr td .profiler-content{
//   min-width: 94%;
//   max-width: 100%;
//   width: 94% !important;
// }
.w-70 {
  max-width: 70px;
  margin-right: 2px;
}

// @media(min-width: 767px){
//   .extended-table td, .extended-table th {
//     max-width: max-content !important;
//   }
// }
.task-table table tr .col-date-tasktable {
  max-width: initial;
  width: 150px;
}

.task-table table tr .col-datemin-tasktable {
  max-width: initial;
  width: 120px;
}

.expandble-header.active-panel .list-view-cnt input {
  color: #000 !important;
}

.dashboard-table .expandble-header .active-panel .btn.btn-primary-outline:hover path {
  color: #000 !important;
}

.fc-button span {
  color: #fff !important;
}

.align-item-center {
  align-items: center;
}

.user-profiler {
  align-items: center;
}

.user-profiler .profiler-content {
  padding-right: 20px !important;
  max-width: 160px;
}

.custom-pagination ul.pagination li {
  --bs-pagination-font-size: 14px !important;
}


header.header-6 .dropdown .dropdown-toggle.btn {
  border: 1px solid #fff;
}

header.header-6 .dropdown .dropdown-toggle.btn:hover {
  color: #753BBD !important;
  background: #fff !important;
}

.dropdown-menu .btn,
.dropdown-menu .dropdown-item {
  font-size: 16px;
}

.dropdown-toggle.active::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "" !important;
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.expandble-header.active-panel td {
  background-color: #828282 !important;
}

.tabs-vertical>.nav .nav-link.active::before,
.tabs-vertical>.nav .nav-link:hover::before {
  border-left-color: #753BBD;
}

.general-info-details {
  background: rgba(117, 59, 189, .05);
  padding-top: 15px;
  padding-bottom: 5px;
  border-top: 1px solid rgba(112, 112, 112, .20);
  ;
  border-bottom: 1px solid rgba(112, 112, 112, .20);
  ;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-left: calc(120px + 2rem);
  padding-right: 1rem;
}

// .general-info-details::before {
//   content: '';
//   background: rgba(117, 59, 189, .05);
//   border-top: 1px solid rgba(112, 112, 112, .20);
//   border-bottom: 1px solid rgba(112, 112, 112, .20);
//   ;
//   position: absolute;
//   top: -.5px;
//   left: -100%;
//   width: 100%;
//   height: calc(100% + 1px);
//   z-index: -1;
// }

// .general-info-details::after {
//   content: '';
//   background: rgba(117, 59, 189, .05);
//   border-top: 1px solid rgba(112, 112, 112, .20);
//   border-bottom: 1px solid rgba(112, 112, 112, .20);
//   ;
//   position: absolute;
//   top: -.5px;
//   right: -100%;
//   width: 100%;
//   height: calc(100% + 1px);
//   z-index: -1;
// }

.basic-table-layout.single-page {
  overflow: hidden;
}


.driverjs-theme header,
.header {
  background-color: white;
  color: $primary-color;
}

.driver-overlay {
  z-index: 1050;
}

.driver-popover {
  z-index: 1060;
}

.navbar {
  z-index: 1040;
}

.driver-popover-navigation-btns button {
  background-color: $primary-color !important;
  padding: .5rem !important;
  color: transparent !important;
  font-weight: 700 !important;
}

.header-6 clr-dropdown button {
  padding: 12px 8px !important;
}


.error-alert {
  display: flex;
  border-radius: 4px;
  width: 20rem;
  padding: .8rem;
  margin: 1rem;
  position: absolute;
  right: 0px;
  background-color: #d9534f;
  color: white;
  font-weight: 800;
  line-height: 32px;
  font-size: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

ngx-otp-input {
  .ngx-otp-input-form {}

  .ngx-otp-input-box {
    width: 30px;
    height: 35px;
    padding: .5rem !important;
    font-size: .7rem !important;
    text-align: center;
    border: 1px solid #753BBD !important;
    border-radius: .3rem !important;
    outline: none;
  }

  .ngx-otp-input-failed {
    width: 30px;
    height: 35px;
    padding: .5rem !important;
    font-size: .7rem !important;
    text-align: center;
    border: 1px solid red !important;
    border-radius: .3rem !important;
    outline: none;
  }
}


.text-button {
  background: none;
  border: none;
  color: #753BBD;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  display: inline;
  outline: none;
}

.text-button:hover {
  text-decoration: underline;
}

.text-button:disabled {
  color: #c0c0c0;
  cursor: not-allowed;
  text-decoration: none;
  pointer-events: none;
}

.vertical-tab-custom .no-border-tabs ul.nav {
  border: none;
  margin-top: 6px;
}

.license-data-table .search-box {
  display: none !important;
}


.flex-1 {
  flex: 1;
}

.border-bottom-4 {
  display: block;
  border: 2px solid #753BBD;
  border-radius: 4px;
  width: 50%;
  margin-top: 4px;
}

.w-full {
  width: 100% !important;
}

.w-max {
  width: max-content;
}

.w-half {
  width: 50%;
}

.h-full {
  height: 100% !important;
}

.mr-1{
  margin-right: 0.25rem;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: .5rem;
}

.slider-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.backdrop {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.slider-container {
  position: fixed;
  right: 0;
  top: 0px;
  width: 40%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  overflow-y: scroll;
}

.backdrop-nested {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.slider-container-nested {
  position: fixed;
  right: 0;
  top: 0px;
  width: 38%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow-y: scroll;
}

.no-scroll {
  overflow: hidden;
}

.google-maps {
  width: 100%;
  min-height: 10rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.google-map-component{
  min-height: 10rem;
  height: 100%;
}

.map-search-box{
  display: block;
  width: 100%;
  margin-bottom: 3px;
}

.edit-details-map {
  height: 100%;
}

.details-map{
  height: -webkit-fill-available;
}

.details-textarea {
  height: 4rem;
  width: 100%;
}

.details table {
  width: 98%;
  margin-left: 15px;
}

.details-modal {
  z-index: 999;
  position: relative;
}

.details-modal .modal-dialog {
  width: 100%;
}

.position-top-right {
  position: absolute;
  top: 5px;
  right: 5px;
}

.position-relative {
  position: relative;
}

.image-grid {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  max-height: 350px;
  overflow-y: auto;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  /* Hide scrollbar in Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar in IE 10+ */
  display: flex;
}

.image-grid::-webkit-scrollbar {
  display: none;
}

.image-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  padding: 4px;
  width: min-content;
  min-width: 200px;
  height: auto;
  padding: 0;
}

.image-item img{
  object-fit: cover;
}


@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .image-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.toggle-margin-bottom {
  margin-bottom: -5px;
}

.pr-0 {
  padding-right: 0px;
}

.property-details-container {
  .clr-accordion {
    height: calc(100% - 90px);

    .clr-accordion-panel {
      height: 100%;

      .clr-accordion-panel-open, .clr-accordion-panel-inactive {
        height: 100%;

        .clr-accordion-content-region {
          height: 100%;

          .clr-accordion-content {
            height: 100%;

            .clr-accordion-inner-content {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.property-details-accordions {
  height: 100%;
}

.details-accordion-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.hide-search{
  .search-box{
      display: none;
  }
}

.tabs-vertical ul{
  overflow-x: hidden !important;
}

.ql-container{
  background: white !important;
}

.innerhtml table{
  width: 100% !important;
}

.canvas-chart{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.canvas-chart canvas{
  width: 60% !important;
  height: 60% !important;
}