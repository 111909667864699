.clr-checkbox-wrapper input[type=checkbox]:checked+.clr-control-label::before {
    background-color: $primary-color;
}


.multiselect-item-checkbox input[type=checkbox]+div:before {
    border: 2px solid $primary-color !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    background: $primary-color !important;
}


.clr-checkbox-wrapper input[type=checkbox]:focus+.clr-control-label::before {
    outline: none;
}

input,
select,
textarea {
    outline: none !important;
}

.btn.btn-info-outline {
    color: $primary-color !important;
    border-color: $primary-color !important;
}

select option:hover {
    background: $primary-color !important;
}

.clr-textarea:focus,
input:focus,
select:focus-within {
    border-color: $primary-color !important;
}

.clr-input:not([readonly]) {
    background: transparent !important;
    border: 1px solid;
    border-radius: 4px;
}

.clr-input[readonly] {
    border: 1px solid #000 !important;
}

.clr-radio-wrapper input[type=radio]:checked+.clr-control-label::before {
    box-shadow: inset 0 0 0 .3rem $primary-color !important;
}

.fc .fc-col-header-cell-cushion {
    color: white;
}

.fc-h-event .fc-event-title-container {
    background-color: $primary-color !important;
}

.fc .fc-daygrid-day-number {
    color: $primary-color !important;
}

.fc .fc-button-primary {
    background-color: $primary-color !important;
}

.fc-col-header tr {
    background-color: $primary-color;
}

.badge.badge-success {
    background-color: #2CC84D;
}

.badge.badge-warning {
    background-color: #FFCF63;
}

.badge.badge-danger {
    background-color: red;
}

.label.label-blue>.badge,
a.label.label-blue>.badge {
    background: $primary-color;
    color: #fff;
    color: $primary-color;
}

.label.label-blue,
.label.label-3,
a.label.label-blue,
a.label.label-3 {
    border-width: var(--clr-label-border-width, 0.05rem);
    border-style: solid;
    border-color: $primary-color;
    background-color: transparent;
}

.label>.badge,
a.label>.badge {
    margin-left: 10px;
    margin-right: -12px;
}

header .branding,
.header .branding {
    padding: 0 1rem;
    min-width: fit-content;
    height: 2rem;
}

.btn.btn-danger.disabled,
.btn.btn-danger:disabled,
.btn-danger .btn.disabled,
.btn-danger .btn:disabled {
    background-color: red;
    color: white;
}

.table>:not(caption)>*>* {
    padding: 0rem 0rem;
}

.btn.btn-outline {
    border-color: #753BBD;
    background-color: transparent;
    color: #753BBD;
}


.btn.btn-outline:hover {
    color: #753BBD;
    background-color: var(--clr-thead-bgcolor, hsl(198, 0%, 98%));
}

.wizard-container .modal-nav {
    display: none;
}

.clr-textarea-wrapper .clr-textarea {
    resize: none;
}

input[type="checkbox"] {
    accent-color: #753BBD;
}

.clr-wizard .clr-wizard-btn.btn-link {
    color: #753BBD;
    padding: 0;
}

.clr-toggle-wrapper input[type=checkbox]:checked+label::before {
    border-color: #753BBD;
    background-color: #753BBD;
    transition-duration: .15s;
    transition-timing-function: ease-in;
    transition-property: border-color,background-color;
}

.clr-textarea-wrapper{
    width: 100%;
    textarea {
        min-height: 8rem !important;
        width: 100% !important;
    }
}